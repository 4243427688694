<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="购买单位名称">
                <a-input v-model="queryParam.buyer_name" placeholder="根据购买单位名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="购买单位代码">
                <a-input v-model="queryParam.buyer_tax_no" placeholder="根据购买单位代码查询" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新增</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete" :disabled="!selectedRowKeys.length">删除</a-button>
      </div>

      <a-table
        size="small"
        row-key="id"
        bordered
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)">编辑</a>
        </template>
      </a-table>

      <a-modal
        :visible="visible"
        :confirmLoading="loading"
        :width="800"
        :maskClosable="false"
        :title="title"
        @ok="handleSubmit"
        @cancel="handleCancel"
      >
        <a-form-model
          layout="vertical"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="购方客户名称" prop="buyer_name">
                <a-input v-model.trim="form.buyer_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="购方单位代码" prop="buyer_tax_no">
                <a-input v-model.trim="form.buyer_tax_no" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="销方地址" prop="buyer_address_phone">
                <a-input v-model.trim="form.buyer_address_phone" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="销方电话">
                <a-input v-model.trim="form.buyer_telphone" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="销方开户行" prop="buyer_bank_account">
                <a-input v-model.trim="form.buyer_bank_account" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="销方账号">
                <a-input v-model.trim="form.buyer_account" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  pageInvoiceBuyerInfo,
  saveOrUpdateInvoiceBuyerInfo,
  deleteInvoiceBuyerInfo
} from '@/api/fms'

export default {
  name: 'FmsInvoiceBuyerInfo',
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '客户ID',
          dataIndex: 'buyer_id'
        },
        {
          title: '购方客户名称',
          dataIndex: 'buyer_name'
        },
        {
          title: '购方单位代码',
          dataIndex: 'buyer_tax_no'
        },
        {
          title: '销方地址',
          dataIndex: 'buyer_address_phone',
          width: 360
        },
        {
          title: '销方电话',
          dataIndex: 'buyer_telphone'
        },
        {
          title: '销方开户行',
          dataIndex: 'buyer_bank_account',
          width: 360
        },
        {
          title: '销方账号',
          dataIndex: 'buyer_account'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      visible: false,
      loading: false,
      title: null,
      form: {
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null
      },
      rules: {
        buyer_name: [{ required: true, message: '请输入购方客户名称', trigger: 'blur' }],
        buyer_tax_no: [{ required: true, message: '请输入购方单位代码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      pageInvoiceBuyerInfo(this.queryParam).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCreate() {
      this.visible = true
      this.title = '新增开票购买单位'
    },
    handleUpdate(record) {
      this.visible = true
      this.title = '修改开票购买单位'
      this.form = Object.assign({}, record)
    },
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          saveOrUpdateInvoiceBuyerInfo(this.form).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.handleCancel()
            this.loadData()
          })
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.form = {
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null
      }
    },
    handleDelete() {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗?',
        onOk: () => {
          const idList = this.selectedRows.map(item => item.id)
          deleteInvoiceBuyerInfo({ ids: idList }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            })
            this.loadData()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
